import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress"

const HubspotForm = ({ formId }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.type = "text/javascript";
    scriptTag.async = true;
    scriptTag.src = `//js.hsforms.net/forms/v2.js`;

    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(scriptTag, s);

    const initHubspotForms = () => {
      window.hbspt.forms.create({
        region: "eu1",
        portalId: "26272530",
        formId,
        target: "#contact-hbspt-form",
      });
      setLoading(false)
    };

    scriptTag.addEventListener("load", initHubspotForms);

    return () => {
      scriptTag.removeEventListener("load", initHubspotForms);
    };
  }, [formId]);

  return <Box style={{
    width: "100%",
    padding: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: "center"
  }} id="contact-hbspt-form">
    {loading && <CircularProgress />}
  </Box>;
};

export default HubspotForm;

import React from "react";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Link from "@components/Link";
import HubSpotForm from "@components/HubSpotForm";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "275px",
    width: "100%",
    alignItems: "center",
    //backgroundColor: theme.palette.primary,
    backgroundColor: "#f3f4f5",
  },
  submit: {
    padding: theme.spacing(2),
    maxWidth: "225px",
    maxHeight: "90px",
    minWidth: "125px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));


function HomepageRibbon(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [PopupOpen, setPopupOpen] = React.useState(false);
  const theme = useTheme();
  const dialogInFullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClickOpen = () => {
    setPopupOpen(true);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  return (
    <div className={classes.root}>
      {/* Left Part of Ribbon */}
      <Box fontWeight="fontWeightBold" width={"100%"} textAlign="center" ml={1}>
        <Typography
          gutterBottom
          component="h2"
          variant="h5"
          style={{ alignSelf: "center", marginBottom: "20px" }}
        >
          {t("Έχεις φορτιστή;")}
        </Typography>

        <Typography color="textSecondary" gutterBottom variant="h6">
          {t(
            "Ανάρτησε τον στο LOADER και απέκτησε επιπλέον έσοδα ή προμηθεύσου φορτιστή από εμάς"
          )}
        </Typography>

        <br />
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"}>
          <Button
            type="submit"
            disableElevation
            variant="contained"
            color="primary"
            size="medium"
            className={classes.submit}
            component={Link}
            href="/station-owner/my-stations"
          >
            <Typography style={{ textAlign: "center" }}>
              {t("Ανάρτησε Φορτιστή")}
            </Typography>
          </Button>
          <Typography
            variant="h6"
            style={{ color: "rgba(0, 0, 0, 0.6)", alignSelf: "center" }}
          ></Typography>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            size="medium"
            className={classes.submit}
            onClick={handleClickOpen}
          >
            <Typography style={{ textAlign: "center" }}>
              {t("Προμηθεύσου Φορτιστή")}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Dialog
        open={PopupOpen}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
        fullScreen={dialogInFullScreen}
        fullWidth={true}
        maxWidth="sm"
      >
        <MuiDialogTitle disableTypography>
          {handleClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <MuiDialogContent>
          <Box marginX={5}>
            <HubSpotForm
              formId={
                i18n.language === "el"
                  ? "a2ad8b68-a828-4bf2-9c56-166f5e817e5d"
                  : "40fc37d2-3cbd-4bd7-8842-0da650aeb992"
              }
            />
          </Box>
        </MuiDialogContent>
        {/* <iframe
          className="airtable-embed airtable-dynamic-height"
          src="https://airtable.com/embed/shr7QYoiiWXcFX3zW?backgroundColor=orange"
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="1077"
        ></iframe> */}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageRibbon);
